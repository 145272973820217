<template>
  <div class="wrapper">
    <header>收款单修改删除</header>
    <div class="split">
      <el-row :gutter="4">
        <el-col :span="12"><el-input v-model="inputVal3" @input="hh_inputChange"></el-input></el-col>
      </el-row>
    </div>
    <!-- 中间滚动区域 -->
    <section>
      <div class="mybody">
      <lzqTable 
        :tableData_hh_dh="tableData_xm_gx" mytitle="收款修改删除初始获取收款单表头" 
        :IsSelection="false" RowKey="item5"
        @tableRowClick="tableRowClick($event)"
        @handleSelectionChange="handleSelectionChange($event)"
      >
      </lzqTable>
      </div>
    </section>
    <!-- 中间选择修改删除弹窗开始 -->
    <el-dialog v-model="dialogFormVisible_xgsctj" width="90%">
      <el-row justify="center">
        <el-col ><span class="mybt"><span class="text-green-600">{{formData.khmc}}</span></span></el-col>
        <el-col ><span class="mybt"><span class="text-red-600">请选择操作:</span></span></el-col>
      </el-row>   
      <el-row justify="center">
        <el-col :span="8"><el-button type="warning" @click="btn_xg_sc_qx('修改')" :loading="myloading">修改{{formData.qxxm}}</el-button></el-col>
        <el-col :span="8"><el-button type="danger" @click="btn_xg_sc_qx('删除')" :loading="myloading">删除{{formData.qxxm}}</el-button></el-col>
        <el-col :span="8"><el-button type="success" @click="btn_xg_sc_qx('取消')" :loading="myloading">取消{{formData.qxxm}}</el-button></el-col>
      </el-row>
    </el-dialog>
    <!-- 中间选择修改删除弹窗结束 -->

    <!-- 中间填写收款单弹窗开始 -->
    <el-dialog v-model="dialogFormVisible_kfzl" width="90%">
      <el-row justify="center">
        <!-- <el-col ><span class="mybt">修改<span class="text-red-600">{{formData.khmc}}</span>收款</span></el-col> -->
        <el-col ><span class="mybt"><span class="text-red-600">{{infoTxt}}</span></span></el-col>
      </el-row>
      <el-form>
        <div style="margin-top: 10px;margin-bottom: 10px;">
          <el-form-item label="日期" label-width="90px">
            <el-date-picker v-model="formData.rq" type="date" format="YYYY-MM-DD"
              value-format="YYYY-MM-DD" style="width: 150px"></el-date-picker>
          </el-form-item>
          <el-form-item>
            <span slot="label" @click.stop="xzjczl_kh" style="width:100px;display:inline-block;text-align:right;">
              <el-icon color="#409EFC" class="no-inherit"> <DArrowRight /> </el-icon>
              客户
            </span>
            <el-input v-model="formData.khmc" style="width:150px"></el-input>
          </el-form-item>  
          
          <el-form-item label="金额" label-width="90px" type="number"><el-input v-model="formData.je" style="width:150px" /> </el-form-item>
          <el-form-item label="账户" label-width="90px">
            <el-select v-model="formData.shdz" collapse-tags style="width: 150px" placeholder="工序"
              @change="SelectOption_zh" size="small" no-data-text="暂无数据">
              <el-option v-for="item in zh_options" :key="item.item1" :label="item.item1" :value="item.item1" />
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <el-row justify="center">
        <el-col :span="12"><el-button type="success" @click="my_add_xg" :loading="myloading">保存{{formData.qxxm}}</el-button></el-col>
      </el-row>
    </el-dialog>
    <!-- 中间填写收款单弹窗结束 -->
    <!-- 右弹客户资料开始 -->
    <el-drawer v-model="drawer_RtoL_kh" title="查看客户" :with-header="false" size="90%">
      <el-row :gutter="4">
        <el-col :span="8"><el-button type="danger" @click="drawer_RtoL_kh = !drawer_RtoL_kh">关闭</el-button></el-col>
        <el-col :span="12"><el-input v-model="inputVal3" @input="kh_inputChange"></el-input></el-col>
      </el-row>
      <lzqTable :tableData_hh_dh="tableData_ckrbb" 
      mytitle="手机查汇总加工单时调用加工客户资料表头" :IsSelection="false" RowKey="item1"
        :Myheight="MYheight2" @tableRowClick="khmclist_Click($event)"></lzqTable>
    </el-drawer>
    <!-- 右弹客户资料结束 -->
    <!-- 底部开始 -->
    <footer>
      <div class="mybutton">
        <el-row class="mb-4">
          <!-- <el-button type="success" @click="btn_add_xg_del('新增')">新增客户</el-button>
          <el-button type="warning" @click="btn_add_xg_del('修改')">修改</el-button>
          <el-button type="danger" @click="btn_add_xg_del('删除')">删除</el-button> -->
          <el-button type="warning" @click="goback">返回</el-button>
          <el-button type="info" @click="toHome">主页</el-button>
        </el-row>
      </div>
    </footer>
    <!-- 底部结束 -->
  </div>
</template>

<script setup>
import myGtdatafun from "../../myapi/myGtdatafun.js";
import dateglobalfun from "../../../dateglobalfun.js"; //日期函数
import { ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { ElMessageBox, ElMessage } from "element-plus";
import lzqTable from '../truecomponents/lzqTable.vue'//引入自定义组件
const tableData_xm_gx = reactive([]);
const tableData_xm_gx_base = reactive([]);
const router = useRouter();
const dialogFormVisible_kfzl = ref(false);
const dialogFormVisible_xgsctj = ref(false);
const myloading = ref(false);
const zh_options = reactive([]); //注意这个定义,搞得半死!!
const drawer_RtoL_kh = ref(false);
const tableData_ckrbb = reactive([]); //注意这个定义,搞得半死!!
const tableData_ckrbb_base = reactive([]); //注意这个定义,搞得半死!!
const MYheight2 = ref(600);
const infoTxt = ref("");

const formData = ref({//表格生成
  rq: "",
  dzkhid: "", //订制客户ID
  dzcpid: "", //订制产品ID
  jgkhid: "", //加工客户ID
  jgcpid: "", //加工产品ID
  azdz: "", //安装地址
  azqy: "", //安装区域
  bz: "", //备注
  bm: "", //编码
  clry: "", //测量人员
  dh: "", //单号
  dj: "", //单价
  dw: "", //单位
  dh: "", //电话
  gd: "", //高度
  gg: "", //规格
  je: "", //金额
  jsr: "", //经手人
  kdrq: "", //开单日期
  khdz: "", //客户地址
  khdh: "", //客户电话
  khmc: "", //客户名称
  kd: "", //宽度
  lldh: "", //联络电话
  lxr: "", //联系人
  lxrdh: "", //联系人电话
  mlhclmc: "", //面料或材料名称
  pm: "", //品名
  spbm: "", //商品编码
  spqm: "", //商品全名
  sjazrq: "", //实际安装日期
  sl: "", //数量
  shdz: "微信", //送货地址
  shdz: "", //送货地址
  xdrq: "", //下单日期
  xh: "", //序号
  yqazrq: "", //要求安装日期
  zdr: "", //制单人
  cztj: "tjjczl", //操作条件
  yhmc: "", //token
  qxxm: "", //权限项目
  id: "1", //
  endrq: "", //结束日期
});
const SelectOption_zh = (Optionvalue) => {
  formData.value.shdz = Optionvalue;//收款账户
};
onMounted(() => {//页面进入时
  formData.value.yhmc = localStorage.getItem(myGtdatafun.XMMC + "yhtoken");
  formData.value.xm = localStorage.getItem(myGtdatafun.XMMC + "xm"); //姓名
  formData.value.gjgx = localStorage.getItem(myGtdatafun.XMMC + "gx"); //工价工序
  formData.value.qxxm == "新增"
  formData.value.cztj = "收款修改删除初始获取收款单";
  formData.value.shdz = "微信"//账户
  khjczl_get();
  setTimeout(() => {
    formData.value.cztj = "收款单获取账户名称";
    khjczl_get_zh();
  }, 1000);
});

const btn_add_xg_del = (tj) => {//三个按钮的操作
  switch (tj) {
    case "新增":
      formData.value.khmc = ""
      formData.value.shdz = ""
      formData.value.qxxm = "新增"
      dialogFormVisible_kfzl.value=true//显示弹窗
      break;
    case "修改":
      if(formData.value.khmc == ""){return}
      formData.value.qxxm = "修改"
      dialogFormVisible_kfzl.value=true//显示弹窗
      break;
    case "删除":
      if(formData.value.khmc == ""){return}
      my_del()//删除
      break; 
  }
}

const btn_xg_sc_qx = (tjstr) => {
  switch (tjstr) {
    case "修改":
      infoTxt.value="修改资料"
      formData.value.cztj = "保存修改账户资料";
      dialogFormVisible_kfzl.value = true
      dialogFormVisible_xgsctj.value = false
    break;
    case "删除":
      dialogFormVisible_xgsctj.value = false
      ElMessageBox.confirm(
        "[" + formData.value.rq + "/" + formData.value.khmc + "/" + formData.value.je + "/" + formData.value.id + "/" + "]修改或删除?",
        "修改或删除?",
        {confirmButtonText: "删除",cancelButtonText: "取消",type: "error",}
      )
        .then(() => {
          formData.value.cztj = "删除一行收款单";
          myGtdatafun
            .MygettableData("html_dysj_ZSG.php", formData.value)
            .then(function (response) {
              console.log(response);
              location.reload(); //重新加载本页
            });
            tableData_xm_gx.splice(
              tableData_xm_gx.findIndex((e) => e.item5 === formData.value.id),
              1
            ); // 将删除id等于1的选项
        })
        .catch(() => {console.log('取消删除一行');dialogFormVisible_xgsctj.value = false});
    break;
    case "取消":
      dialogFormVisible_xgsctj.value = false
    break;
  }  
}
const save_add_xg_jczl = () => {//修改
  console.log('保存save_add_xg_jc8zl',formData.value);
  if (formData.value.khmc == "") {
    ElMessage({ message: '名称不能为空,保存不成功!', type: 'error', });
    return;
  }
  let cansavekh = 'yes'; 
  // tableData_xm_gx.forEach((ite, index) => {//供应商名称重复,不保存
  //   if (ite.item1 == formData.value.khmc) {
  //     ElMessage({ message: '名称重复了,保存不成功!', type: 'error', });
  //     cansavekh = 'no';
  //   }
  // });
  if (cansavekh == 'no') {
    dialogFormVisible_kfzl.value = false;//隐藏弹窗
    formData.value.khmc = ""; formData.value.lxr = "";formData.value.dh = "";
    return;
  }
  myloading.value = true 
  
  khjczl_get();
  dialogFormVisible_kfzl.value = false;
  formData.value.khmc = ""; formData.value.lxr = "";formData.value.dh = "";
} 

const my_del = () =>{//删除一行
  ElMessageBox.confirm(
    "[" + formData.value.khmc + "/" + formData.value.shdz + "/" + "]修改或删除?",
    "修改或删除?",
    { confirmButtonText: "确认删除", cancelButtonText: "取消删除", type: "error", }
  )
    .then(() => {
      formData.value.cztj = "手机端删除一行加工客户资料";
      myGtdatafun
        .MygettableData("html_dysj_ZSG.php", formData.value)
        .then(function (response) {
          console.log(response);
          location.reload(); //重新加载本页
        });
      tableData_xm_gx.splice(
        tableData_xm_gx.findIndex((e) => e.item3 === formData.value.id),
        1
      ); // 将删除id等于1的选项
    })
    .catch(() => {
      console.log('取消删除');
      // dialogFormVisible_kfzl.value = !dialogFormVisible_kfzl.value;
      // formData.value.khmc=row.item1
      // formData.value.shdz=row.item2
    });
}
const xzjczl_kh = () => {
  console.log('选择右弹窗客户');
    formData.value.cztj="手机查汇总加工单时调用加工客户资料"
  //lzqformDrawer_ref.value.open()//显示客户列表
  get_gwgx_cxkh()
}
const get_gwgx_cxkh = async () => {
  await
    myGtdatafun
      .MygettableData("html_dysj_ZSG.php", formData.value)
      .then(function (response) {
        console.log(response, formData.value);
        if (response.length <= 0) { return }
        myloading.value = false
        drawer_RtoL_kh.value=true//显示客户列表
        tableData_ckrbb.length = 0; //数组置空,不然会重复
        tableData_ckrbb_base.length = 0; //数组置空,不然会重复
        response.forEach((item) => {
          tableData_ckrbb.push(item);
          tableData_ckrbb_base.push(item);
        })
        
      .catch(function (error) {
      });
    });
};

const my_add_xg = () => {//保存新增和修改
  console.log('保存修改m1y_add_xg',formData.value);
  if (formData.value.je == "") {
    ElMessage({ message: '金额不能为空,保存不成功!', type: 'error', });
    return;
  }
  //客户名称重复了,不保存
  let cansavekh = 'yes'; 
  myloading.value = true 
  formData.value.cztj = "保存修改收款单";
  khjczl_get();
  dialogFormVisible_kfzl.value = false;
  formData.value.khmc = ""; formData.value.je = ""; formData.value.shdz = "";
} 
const khmclist_Click =(row) =>{
  formData.value.khmc=row.item1
  drawer_RtoL_kh.value=false//关闭客户列表
  // if (route.query.bmmc == "查加工单") {
  //   mytitle_bb.value = "查询加工单报表表头"
  //   formData.value.cztj = "手机端查询汇总加工单";
  // }
}
const handleSelectionChange = (arr) => {//选择复选框
  console.log('handleSelectionChange[]', arr);
  // let ids = "";
  arr.forEach((item, index) => {
    formData.value.khmc = item.item1
    // formData.value.shdz = item.item2
    formData.value.id = item.item3
    // if (index == 0) {
    //   ids = item.item3;
    // } else {
    //   ids = ids + "," + item.item3;
    // }
  });
  console.log("handleSelectionChange", formData.value);
}

const tableRowClick = (row) => {//点击一行跳转
  console.log('修改或删除',row);
  formData.value.rq = row.item1
  formData.value.khmc = row.item2
  formData.value.je =  row.item3
  formData.value.id = row.item5
  dialogFormVisible_xgsctj.value=true;
  return
  // formData.value.khmc = row.item1
  // formData.value.shdz = "微信"//账户
  // formData.value.id = row.item3
  // dialogFormVisible_kfzl.value = true

  // // router.push({
  // //   path: "a_jgd_2_2",
  // //   query: {
  // //     my_row: JSON.stringify(row),
  // //   },
  // // });

  if (row.item1 == '查无资料') return
  ElMessageBox.confirm(
    "[" + row.item1 + "/" + row.item2 + "/" + row.item3 + "/" + row.item4 + "/" + "]修改或删除?",
    "修改或删除?",
    {confirmButtonText: "删除",cancelButtonText: "修改",type: "error",}
  )
    .then(() => {
      formData.value.id = row.item5;
      formData.value.cztj = "删除一行收款单";
      myGtdatafun
        .MygettableData("html_dysj_ZSG.php", formData.value)
        .then(function (response) {
          console.log(response);
          location.reload(); //重新加载本页
        });
      // tableData_xm_gx.splice(
      //   tableData_xm_gx.findIndex((e) => e.item5 === row.item5),
      //   1
      // ); // 将删除id等于1的选项
    })
    .catch(() => {
      console.log('这是修改',row,formData.value);
      dialogFormVisible_kfzl.value = !dialogFormVisible_kfzl.value;
      formData.value.rq=row.item1
      formData.value.khmc=row.item2
      formData.value.shdz=row.item3
      formData.value.je=row.item4
      formData.value.id=row.item5
    });
};

const khjczl_get = async () => {//提交到数据库
  myGtdatafun
    .MygettableData("html_dysj_ZSG.php", formData.value) 
    .then(function (response) {
      console.log( '11111',response, formData.value);
      myloading.value = false
      if (response.length <= 0) { return }
      formData.value.qxxm = ""
      switch (formData.value.cztj) {
        case "收款修改删除初始获取收款单":
          tableData_xm_gx.length = 0;
          tableData_xm_gx_base.length = 0;
          response.forEach((item) => {
            tableData_xm_gx.push(item);
            tableData_xm_gx_base.push(item);
          });
          break;
        case "保存修改收款单":
          location.reload(); //重新加载本页
          break;
        case "删除一行收款单":
          location.reload(); //重新加载本页
          break;
      }
      
        
    })
    .catch(function (error) {
    });
};
const khjczl_get_zh = async () => {//提交到数据库
  myGtdatafun
    .MygettableData("html_dysj_ZSG.php", formData.value) 
    .then(function (response) {
      console.log(formData.value.cztj, response);
      myloading.value = false
      console.log('1',response,response);
      if (response.length <= 0) { return }
      zh_options.length = 0;//清空原来接收部门
      response.forEach((item) => {
        zh_options.push(item); //注1意这种写法,搞得半死!!
      });
        
    })
    .catch(function (error) {
    });
};
const toHome = () => {
  router.push("/");
};
const goback = () => {
  router.go(-1);
};

//本页面筛选
const inputVal3 = ref("");
const hh_inputChange = async (e) => {
  tableData_xm_gx.length = 0; //先清空显示的数组
  tableData_xm_gx_base.forEach((item, index) => {
    if (item.item2.indexOf(inputVal3.value.toUpperCase()) >= 0 ||
      item.item3.indexOf(inputVal3.value) >= 0
    ) {
      tableData_xm_gx.push(item); //再把选到的加入显示数组
    }
  });
};
const kh_inputChange = async (e) => {
  tableData_ckrbb.length = 0; //先清空显示的数组
  tableData_ckrbb_base.forEach((item, index) => {
    if (item.item1.indexOf(inputVal3.value.toUpperCase()) >= 0) {
      tableData_ckrbb.push(item); //再把选到的加入显示数组
    }
  });
  setInterval(() => {
    inputVal3.value=""
  }, 1000);
};

</script>
<style scoped>
html,
body {
  height: 100%;
}

.wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

header,
footer {
  height: 40px;
  line-height: 40px;
  background-color: #d8d8d8;
  text-align: center;
}

section {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  overflow: auto;
  /* winphone8和android4+ */
  -webkit-overflow-scrolling: touch;
  /* ios5+ */
}

.mybutton {
  display: flex;
  justify-content: center;
}

:deep(.el-collapse-item__header) {
  color: red;
  font-weight: 600;
  margin-left: 10px;
}

:deep(.el-form-item__label) {
  color: #000;
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
}

:deep(.el-collapse-item__content) {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
}

.mybt {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin: 0 auto;
}

:deep(.el-carousel__container) {
  height: 380px;
}

img {
  height: 380px;
}

.split {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>